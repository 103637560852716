import React from 'react';
import getYouTubeID from 'get-youtube-id';

import { VideoProps } from './types';
import * as Styled from './styles';

const Video: React.FC<VideoProps> = ({ url, title }) => {
  const videoId = getYouTubeID(url, { fuzzy: false });

  return (
    <Styled.VideoIframe
      src={`https://www.youtube.com/embed/${videoId}?&autoplay=1&rel=0&modestbranding=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      {...{ title }}
    />
  );
};

export default Video;
